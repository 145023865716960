
.box_name > h1 {
  font-size: 20pt;
  font-family: "neue-haas-grotesk-display", sans-serif;
  font-weight: 500;
  line-height: 20pt;
  width: 100%;
  font-style: normal;
  white-space: nowrap;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.box_name {
  padding: 2px;
  z-index: -5;
  border-radius: 5px;
  transition: opacity 0.3s;
  font-weight: 400 !important;
}

.box_value {
  transform: translate(-50%, -50%);
  width: 100px;
  background-color: var(--hover_blue);
  color: #fff;
  padding: 1px;
  white-space: nowrap;
  opacity: 1;
  position: relative;
  float: left;
  z-index: 12;
  position: absolute;
  pointer-events: none;
  text-align: center;
  border-radius: 3px;
  opacity: 1;
  transition: all 1s;
  width: auto;

}
  


.temporary_title {
  background-color: white;
  padding: 8px;
  transform: translate(-50%, -50%);
  font-size: 20pt;
  font-family: "neue-haas-grotesk-text", sans-serif;
  font-weight: 600;
}

.hovered_name {
  color: var(--hover_blue);
}

.highlighted_name {
  color: grey;
}

.artificial_name{
  transform: translate(-120%, 0%);
  text-align: right;
}

.unhighlighted > h1{
  color: rgb(113, 113, 113)
}

.hide_box {
  opacity: 0;
  transition: all 1s;
}




.box_value > h1 {
  font-size: 15pt;
  font-family: "neue-haas-grotesk-display", sans-serif;
  font-weight: 500;
  font-style: normal;
  white-space: nowrap;
  padding: 5px;
  border-radius: 5px;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.box_value:after {
  content: " ";
  position: absolute;
  right: 50%;
  transform: translate(50%, 0%);
  bottom: -9px;
  border-top: 10px solid var(--hover_blue);
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: none;
}

.box_value.left::before {
  content: "";
  position: absolute;
  top: 50%;                        
  margin-top: -10px;                
  left:-16px;
  border: solid 9px transparent;
  border-right-color: var(--hover_blue);
  z-index: 1;
}

.box_value.left::after {
  visibility: hidden;
}
  




@media only screen and (max-width: 900px){

  .rotate_phone_container {
    visibility:visible;
  }

  .rotate_phone_header {
    font-size: 15pt;
    line-height: 16pt;
  }
  
  .button_column .click_button {
    visibility: hidden;
  }
  .small_click_left {
    display: block !important;
  }
  .small_click_right {
    display: block !important;
  }
}

@media only screen and (max-width: 600px) {
  .box_name > h1 {
    font-size: 20pt;
  }
  .box_value > h1 {
    font-size: 20pt;
  }

}


@media only screen and (min-width: 900px) and (max-width:1200px) {

  .box_name > h1 {
    font-size: 15pt; 
    line-height: 15pt;
    transform: translate(0%,10%);

  }
  .box_name {
    width: 100px;
  }

  .box_value > h1 {
    font-size: 15pt;
  }


}


@media only screen and (max-height: 600px) {
  .box_name > h1 {
    font-size: 8pt;
  }
  .box_value > h1 {
    font-size: 8pt;
  }
  /*
  .box_value {
    width: 60px;
  }
  */
  .box_value:after {
    right: 50%;
    transform: translate(50%, 0%);

  }
}
@media only screen and (min-height: 600px) and (max-height:1200px) {
  .box_name > h1 {
    font-size: 12pt;
  }
  .box_value > h1 {
    font-size: 12pt;
  }
  /*
  .box_value {
    width: 80px;
  }
  */
  .box_value:after {
    right: 50%;
    transform: translate(50%, 0%);
  }
}

@media only screen and (max-height: 500px) {
  .box_name > h1 {
    font-size: 7pt ;
  }

  .box_name {
    transform: translate(100%, 100%);

  }
  .box_value > h1 {
    font-size: 7pt;
    padding: 2px;
    min-height: 0;
    padding-left: 4px;
    padding-right: 4px;
  }

  .box_value {
    padding: 0px;
    width: auto;
  }

  .box_value:after {
    border-top: 5px solid var(--hover_blue);
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    bottom: -4px;
  }

  .box_value.left::before {
    border: solid 5px transparent;
  }

}