.clickmodal-container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: red;
    left: 0%;
    top: 0%;
}

.clickmodal-box {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 80%;
    height: 80%;
    background-color: white;
    transform: translate(-50%, -50%);
}