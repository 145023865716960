body {
  margin: 0;
  font-family: "neue-haas-grotesk-text", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: fill-available;
  height: -webkit-fill-available;
  height: -moz-available; /* For Firefox */

  width: fill-available;
  width: -webkit-fill-available;
  background-color: var(--background-color);
  width: -moz-available; /* For Firefox */
  height: 100%;
  height: 100vh;

  height: 100vh;
  height: var(--app-height);

}

#root {
  height: fill-available;
  height: -webkit-fill-available;
  height: -moz-available; /* For Firefox */

  width: fill-available;
  width: -webkit-fill-available;
  width: -moz-available; /* For Firefox */
  height: 100%;
  height: 100vh;

  height: 100vh;
  height: var(--app-height);

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

canvas{
}

.App {
  height: fill-available;
  height: -webkit-fill-available;
  height: -moz-available; /* For Firefox */

  width: fill-available;
  width: -webkit-fill-available;
  width: -moz-available; /* For Firefox */
  height: 100%;

}

html { 
  height: fill-available;
  height: -webkit-fill-available;
  height: -moz-available; /* For Firefox */

  width: fill-available;
  width: -webkit-fill-available;
  height: 100vh; /* Full viewport height */

  height: 100vh;
  height: var(--app-height);

}

@supports (height: 100dvh) {
  html {
    height: 100dvh;
  }
}

.game_container {
  min-height: fill-available;
  min-height: -webkit-fill-available;
  min-width: -moz-available; /* For Firefox */

  position: relative;
  padding: 16px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0px;
  height: 100%;

}
.vis-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 0;
}
